<template>
  <div class="Webhook">
    <ValidationObserver v-slot="{ handleSubmit }" slim>
      <form @submit.prevent="handleSubmit(onFormSubmit)">
        <div v-if="general">
          <div class="description">
            In order to receive notifications for your monitors via webhook you should provide the webhook URL.
            We will make <strong>POST</strong> request to the URL provided.
          </div>
          <base-alert type="error" v-if="webHookError" class="mt-3">
            Something went wrong
          </base-alert>

          <div class="webhook" v-for="(channel, index) in userGeneralNotificationChannels" :key="index">
            <div class="remove"
                 @click.prevent="deleteGeneralChannel(channel)">
              <icon-trash width="18"
                          height="18" />
            </div>
            <div class="text">{{ channel.item_value }}</div>
          </div>

          <template v-if="!userGeneralNotificationChannels.length">
            <base-textbox ref="webhook"
                          placeholder="https://your-site.com/webhook"
                          rules="required|urlWithProtocol"
                          v-model="webhook" />
            <base-button class="button-save"
                         type="submit"
                         color="primary"
                         :loading="submitting">Save</base-button>
          </template>
        </div>
        <div v-else>
          <div class="description" v-if="!channel">
            In order to receive notifications for
            <monitor-label :monitor="monitor"
                           :show-favicon="false" /> via webhook you should provide the webhook URL.
            We will make <strong>POST</strong> request to the URL provided.
          </div>

          <div class="description" v-if="channel">
            You have enabled webhook notifications for <monitor-label :monitor="monitor"
                                                                     :show-favicon="false" />.
            <br />
            We will make <strong>POST</strong> request to the provided URL
          </div>

          <base-alert type="error" v-if="webHookError" class="mt-3">
            Something went wrong
          </base-alert>

          <div class="webhook" v-if="channel">
            <div class="remove"
                 @click.prevent="deleteChannel(channel)">
              <icon-trash width="18"
                          height="18" />
            </div>
            <div class="text">{{ channel.webhook }}</div>
            <div class="d-flex ml-4" style="flex-direction: column">
              <send-online-request :monitor="monitor" class="ml-2"/>
              <send-offline-request :monitor="monitor" class="ml-2 mt-2" />
              <send-ssl-expires-soon-request :monitor="monitor" class="ml-2 mt-2"/>
            </div>
          </div>

          <template v-if="!channel">
            <base-textbox ref="webhook"
                          placeholder="https://your-site.com/webhook"
                          rules="required|urlWithProtocol"
                          v-model="webhook" />
            <base-button class="button-save"
                         type="submit"
                         color="primary"
                         :loading="submitting">Save</base-button>
          </template>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import MonitorLabel from '@/components/Monitor/MonitorLabel.vue'
import SendOnlineRequest from './SendOnlineRequest.vue'
import SendOfflineRequest from './SendOfflineRequest.vue'
import SendSslExpiresSoonRequest from './SendSslExpiresSoonRequest.vue'

import notificationChannelsApi from '@/api/monitorNotifications/notificationChannelsApi.js'
import webhookApi from '@/api/monitorNotifications/webhookApi.js'
import usersApi from '../../../../api/usersApi'

export default {
  components: {
    SendSslExpiresSoonRequest,
    MonitorLabel,
    SendOnlineRequest,
    SendOfflineRequest
  },

  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      webhook: '',
      channel: null,
      submitting: false,
      webHookError: false,
      userGeneralNotificationChannels: null
    }
  },

  created () {
    if (this.general) {
      this.loadGeneralNotificationChannels()
    }
  },

  mounted () {
    this.flushNewHookFocus()
  },

  methods: {
    async onFormSubmit () {
      if (this.general) {
        this.webHookError = false
        this.submitting = true

        try {
          await webhookApi.generalStore(this.webhook)
          await this.loadGeneralNotificationChannels()
        } catch (e) {
          this.webHookError = true
        }

        this.submitting = false
      } else {
        this.webHookError = false
        this.submitting = true

        try {
          await webhookApi.store(this.monitor.id, this.webhook)
          this.$emit('update')
        } catch (e) {
          this.webHookError = true
        }

        this.submitting = false

        this.flushNewHookFocus()
      }
    },

    async deleteChannel (channel) {
      await notificationChannelsApi.delete(channel.id)
      this.$emit('update')
    },

    async deleteGeneralChannel (channel) {
      await notificationChannelsApi.generalDelete(channel.id)
      await this.loadGeneralNotificationChannels()
    },

    async loadGeneralNotificationChannels () {
      try {
        this.userGeneralNotificationChannels = await usersApi.getGeneralChannels('webhook')
      } catch (e) {
        this.generalError = true
      }
    },

    flushNewHookFocus () {
      if (this.$refs.webhook) {
        this.$refs.webhook.focus()
      }
    }
  },

  watch: {
    monitor: {
      handler: function () {
        if (!this.general) {
          const channels = this.monitor.notification_channels
            .filter(channel => channel.via === 'webhook')

          this.channel = channels.length > 0 ? channels[0] : null
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .Webhook {
    max-width: 550px;

    .description {
      margin-bottom: 10px;
      color: #777;
      line-height: 1.5;
    }

    .webhook {
      display: flex;
      align-items: center;

      .remove {
        cursor: pointer;
        padding: 10px 10px 10px 0;

        &:hover /deep/ svg g {
          transition: 0.2s all ease;
          fill: map-get($colors, purple-1) !important;
        }
      }

      .text {
        margin-bottom: 3px;
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
